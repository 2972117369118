import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  concat,
  defaultDataIdFromObject,
} from '@apollo/client'
import fetch from 'unfetch'

export const httpLink = new HttpLink({
  // @ts-ignore
  fetch,
  uri: '/graphql',
  credentials: 'same-origin',
})

export const authLink = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: httpHeaders,
  })
  return forward(operation)
})

export const httpHeaders = {
  accept: 'application/json',
  'content-type': 'application/json',
}

export const client = new ApolloClient({
  link: concat(authLink, httpLink),
  cache: new InMemoryCache({
    dataIdFromObject: (object: any) => {
      // Do NOT cache operands by id. The ids are unique per expression, but NOT globally unique.
      if (object.__typename.endsWith('Operand')) {
        return
      }

      switch (object.__typename) {
        case 'ApiKey':
          return `ApiKey:${object.key}`
        case 'HandbookRecipient':
          return `HandbookRecipient:${object.handbookId}:${object.personId}`
        case 'HandbookVersion':
          return `HandbookVersion:${object.handbookId}:${object.version}`
        case 'HandbookVersionSummary':
          return `HandbookVersionSummary:${object.handbookId}:${object.version}`
        case 'ManagerSettings':
          return `ManagerSettings:${object.organizationId}`
        default:
          return defaultDataIdFromObject(object)
      }
    },
    // TODO: https://www.apollographql.com/docs/react/data/fragments/#generating-possibletypes-automatically
    possibleTypes: {
      Asset: ['Image', 'Video'],
      DocumentGroupInterface: [
        'DocumentGroup',
        'PeopleDocumentGroup',
        'SelectDocumentGroup',
        'TextDocumentGroup',
      ],
      Event: [
        'DocumentAddedEvent',
        'DocumentArchivedEvent',
        'DocumentCreatedEvent',
        'DocumentPublishedEvent',
        'DocumentRemovedEvent',
        'DocumentReviewRoundAddedEvent',
        'DocumentReviewerAddedEvent',
        'DocumentReviewerFeedbackAddedEvent',
        'DocumentReviewerRemovedEvent',
        'DocumentUnarchivedEvent',
        'DocumentUpdatedEvent',
        'FeatureFlagAddedEvent',
        'FeatureFlagRemovedEvent',
        'HandbookCreatedEvent',
        'HandbookPublishedEvent',
        'HandbookSignatureRoundCreatedEvent',
        'HandbookSignedEvent',
        'HandbookViewedEvent',
        'PeopleSyncEvent',
        'PersonCreatedEvent',
        'PersonNotifiedEvent',
        'PersonAddedToHandbookEvent',
        'PersonRemovedFromHandbookEvent',
        'PersonArchivedEvent',
        'PersonUnarchivedEvent',
      ],
      HandbookAsset: ['HandbookImage', 'HandbookPdf'],
      PropertyValue: [
        'DatePropertyValue',
        'NumberPropertyValue',
        'PersonPropertyValue',
        'SelectPropertyValue',
        'TextPropertyValue',
        'UrlPropertyValue',
      ],
      Integration: [
        'BambooHRIntegration',
        'RipplingIntegration',
        'SftpIntegration',
        'HttpsIntegration',
      ],
    },
  }),
})
